import React from "react"

export default ({ fill = "#EDEAE3" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 13V0H5V2.36364H14.015L0 16.3787L1.62132 18L15.6364 3.98496V13H18Z"
        fill={fill}
      />
    </svg>
  )
}
