import React from "react"
import Header from "./header"
import Footer from "./footer"
import Hat from "./hat"
import "../styles/style.scss"
import { useLocation } from "@reach/router"

const Layout = ({ children = null, title, ...rest }) => {
  const location = useLocation()

  const activeSite =
    location && location.pathname.startsWith("/playopolis/")
      ? "playopolis"
      : "64ways"

  let extraProps = { ...rest }
  const propsClass = extraProps.className || ""
  const className = `${propsClass} layout-wrap ${activeSite}`

  return (
    <div className={className}>
      <Hat title={title} />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
