import React from "react"
import { graphql } from "gatsby"
import TeaserTile from "../components/teaser-tile"
import ModalLayout from "../components/modal-layout"
import { darkFill } from "../common/settings"

function Landing({ data, location }) {
  const { page } = data
  const {
    title,
    content,
    wpParent,
    wpChildren: { nodes: pageList },
  } = page

  const { uri: parentURI, title: parentTitle } = wpParent?.node || {}

  const contentList = []
  pageList.forEach((page) => {
    const category =
      page.template?.templateName != "Sixty Four Ways Map Listing"
        ? "Article"
        : "Map"

    contentList.push({
      ...page,
      category,
    })
  })

  return (
    <ModalLayout
      location={location}
      title={title}
      headerProps={{
        backLocation: parentURI || -1,
        backTitle: parentTitle || "Back",
        theme: darkFill,
      }}
      className="page landing-page"
    >
      <div className="content-container">
        <article>
          <h1 className="page-title">{title}</h1>
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="tiles">
            {contentList &&
              contentList.map((content, i) => {
                return <TeaserTile {...content} key={`teaser-tile-${i}`} />
              })}
          </div>
        </article>
      </div>
    </ModalLayout>
  )
}

export default Landing

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1440) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query querySixtyFourWaysLanding($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      wpParent {
        node {
          uri
          ... on WpPage {
            id
            title
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            uri
            title
            content
            template {
              templateName
            }
            featuredImage {
              node {
                localFile {
                  ...HeroImage
                }
              }
            }
          }
        }
      }
    }
  }
`
