import React, { useState, useEffect, useRef } from "react"

import PlayButton from "../assets/svg/play-dark-outline.svg"
import PauseButton from "../assets/svg/pause-dark-outline.svg"

import Volume from "../assets/svg/volume-dark.svg"

export default ({ audioFile }) => {
  const [play, setPlay] = useState(false)
  const [showVolume, setShowVolume] = useState(false)
  const [playIcon, setPlayIcon] = useState(PlayButton)

  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)

  const [volume, setVolume] = useState(25)

  const refContainer = useRef(null)

  const playAudio = () => {
    if (!play) {
      refContainer.current.play()
      setPlayIcon(PauseButton)
    } else {
      refContainer.current.pause()
      setPlayIcon(PlayButton)
    }

    setPlay(!play)
  }

  useEffect(() => {
    setTimeout(() => {
      setDuration(refContainer.current.duration)
    }, 100)

    const timeUpdateListener = () => {
      if (refContainer.current) {
        setCurrentTime(refContainer.current.currentTime)
      }
    }

    refContainer.current.addEventListener("timeupdate", timeUpdateListener)

    return () => {
      if (refContainer.current) {
        refContainer.current.removeEventListener(
          "timeupdate",
          timeUpdateListener
        )
      }
    }
  }, [refContainer.current])

  const handleVolumeChange = (event) => {
    setVolume(parseInt(event.target.value))

    refContainer.current.volume = volume / 100
  }

  const setTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.round((time / 60 - minutes) * 60)

    const minutesString =
      minutes.toString().length == 1 ? "0" + minutes : minutes
    const secondsString =
      seconds.toString().length == 1 ? "0" + seconds : seconds
    return minutesString + ":" + secondsString
  }
  return (
    <>
      <div className="playerControls">
        <img
          className="playerIcon"
          src={playIcon}
          onClick={playAudio}
          alt="play audio"
        />

        <img
          className="playerIcon"
          src={Volume}
          onClick={() => setShowVolume(!showVolume)}
          alt="audio volume"
        />
        {showVolume && (
          <input
            type="range"
            min="1"
            max="100"
            value={volume}
            onChange={handleVolumeChange}
            className="volumeBar"
          />
        )}
      </div>
      <progress className="player" value={currentTime} max={duration} />
      <div className="playerTime">
        <div>{setTime(currentTime)}</div>
        <div>{setTime(duration)}</div>
      </div>

      <audio
        className="podcastPlayer"
        controls
        src={audioFile}
        ref={refContainer}
        preload="metadata"
      >
        Your browser does not support the <code>audio</code>
        element.
      </audio>
    </>
  )
}
