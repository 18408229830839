import React, { useEffect } from "react"
import Logo from "../components/icons/animated-logo"
import Layout from "../components/layout"
import { isAndroid, isIOS, isMobile } from "react-device-detect"
import { navigate } from "@reach/router"

export default () => {
  useEffect(() => {
    let redirect = "/"

    if (isMobile && (isAndroid || isIOS)) {
      if (isAndroid) {
        redirect =
          "https://play.google.com/store/apps/details?id=net.troyinnocent.sixtyfourwaysofbeing"
      } else {
        redirect = "https://apps.apple.com/us/app/64-ways-of-being/id1491263845"
      }
    }

    setTimeout(() => {
      navigate(redirect)
    }, 1000)
  }, [])

  return (
    <Layout title="64 Ways of Being">
      <div className="content-container">
        Redirecting mobile users to your app store... <br />
        Desktop users will be redirected to the home page.
      </div>
    </Layout>
  )
}
