import React from "react"
import { useSpring, animated, config, interpolate } from "react-spring"
import { navigate } from "gatsby"
import { useTimeout } from "../../utils/timers"

export default (props) => {
  const { fill, animate = true, className = "" } = props
  const conf = config.molasses

  const fillSpring = useSpring({
    from: { fill: "#00000000" },
    to: { fill },
    config: conf,
    delay: animate ? 3000 : 0,
    immediate: !animate,
  })

  useTimeout(
    () => {
      navigate("/about/", { state: { redirect: true } })
    },
    animate ? 6000 : null
  )

  const transformConfig = [
    [-500, -200, 70],
    [500, -200, 50],
    [-500, 200, -90],
    [500, 200, -50],
  ]

  const transforms = []
  transformConfig.forEach((t, i) => {
    const spring = useSpring({
      from: {
        tx: t[0],
        ty: t[1],
        r: t[2],
        o: 0,
      },
      to: {
        tx: 0,
        ty: 0,
        r: 0,
        o: 1,
      },
      config: conf,
      delay: animate ? Math.random() * 700 + 300 : 0,
      immediate: !animate,
    })

    transforms[i] = {
      transform: interpolate(
        [spring.tx, spring.ty, spring.r], // tslint:disable-line
        (x, y, r) => `translate(${x}px, ${y}px) rotate(${r}deg)`
      ),
      opacity: interpolate(spring.o, (o) => `${o}`), // tslint:disable-line
    }
  })

  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 278.83 186.01"
      stroke={"#ffffff"}
      fill={interpolate(fillSpring.fill, (f) => f)}
      className={className}
    >
      <g>
        <animated.path
          style={{ ...transforms[1] }}
          d="M77.08,72h0a57,57,0,1,0,57.11,57A57.05,57.05,0,0,0,77.07,72Zm0,94.35A37.35,37.35,0,1,1,114.49,129,37.38,37.38,0,0,1,77.07,166.36Z"
        />
        <animated.polygon
          style={{ ...transforms[0] }}
          points="256.39 72.01 256.39 115.49 278.83 115.49 278.83 134.53 256.39 134.53 256.39 186.01 237.35 186.01 237.35 134.53 164.83 134.53 164.83 115.5 237.35 115.49 237.35 72.01 256.39 72.01 256.39 72.01"
        />
        <animated.polygon
          style={{ ...transforms[3] }}
          points="81.32 0 95.31 13.99 13.99 95.31 0 81.32 81.32 0 81.32 0"
        />
        <animated.polygon
          style={{ ...transforms[2] }}
          points="239.56 0 253.55 13.99 172.23 95.31 158.24 81.32 239.56 0 239.56 0"
        />
      </g>
    </animated.svg>
  )
}
