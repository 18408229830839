import React from "react"

export default ({ fill = "#EDEAE3" }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="24" stroke={fill} strokeWidth="2" />
      <path
        d="M25.354 34.8988L22.8407 34.9663C21.8968 34.9663 21.4248 34.82 21.4248 34.5276C21.4248 34.2127 21.6018 33.999 21.9558 33.8865C21.9794 33.8865 22.0265 33.8753 22.0973 33.8528C22.6401 33.6728 22.9941 33.4029 23.1593 33.0429C23.3245 32.683 23.4071 30.9397 23.4071 27.8129V24.8436C23.4071 24.1912 23.3245 23.6738 23.1593 23.2914C22.9941 22.909 22.8407 22.6728 22.6991 22.5828C22.5575 22.4928 22.3097 22.4254 21.9558 22.3804C21.6254 22.3129 21.3776 22.2566 21.2124 22.2117C21.0708 22.1442 21 22.0542 21 21.9417C21 21.8067 21.0236 21.7055 21.0708 21.638C21.1416 21.5706 21.2596 21.5031 21.4248 21.4356C21.6136 21.3456 21.8024 21.2669 21.9912 21.1994C22.6519 21.0194 23.1947 20.8395 23.6195 20.6595C24.0442 20.4795 24.3628 20.3446 24.5752 20.2546C24.8112 20.1646 24.9646 20.0971 25.0354 20.0521C25.2006 19.9847 25.4012 19.9509 25.6372 19.9509C25.9912 19.9509 26.1681 20.1646 26.1681 20.592L25.9558 22.2117V31.4571C25.9558 32.1319 26.0619 32.6605 26.2743 33.0429C26.5103 33.4254 26.7463 33.6616 26.9823 33.7515C27.2419 33.8415 27.5369 33.8978 27.8673 33.9202C28.1976 33.9427 28.41 33.954 28.5044 33.954C28.8348 33.999 29 34.1564 29 34.4264C29 34.8088 28.7994 35 28.3982 35L27.8673 34.9663L25.354 34.8988ZM24.7522 16.7791C24.2802 16.7791 23.8673 16.5429 23.5133 16.0706C23.1829 15.5982 23.0177 15.2157 23.0177 14.9233C23.0177 14.6084 23.2183 14.2147 23.6195 13.7423C24.0442 13.2474 24.4336 13 24.7876 13C25.1652 13 25.531 13.2362 25.885 13.7086C26.2389 14.1585 26.4159 14.5634 26.4159 14.9233C26.4159 15.2832 26.1917 15.6881 25.7434 16.138C25.295 16.5654 24.9646 16.7791 24.7522 16.7791Z"
        fill={fill}
      />
    </svg>
  )
}
