import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import TeaserTile from "../components/teaser-tile"

function PlayopolisLanding({ data, location }) {
  const [filter, setFilter] = useState("")

  const {
    page,
    posts: { nodes = [] },
  } = data
  const { title, content } = page

  const contentList = []
  nodes.forEach((post) => {
    const { templateName } = post.template
    const category = templateName.replace("Playopolis ", "")

    if (category === filter || filter === "") {
      contentList.push({
        ...post,
        category,
      })
    }
  })

  const handleFilter = (filterVal: string) => {
    const newFilterVal = filter === filterVal ? "" : filterVal
    setFilter(newFilterVal)
  }

  const isActiveButton = (value: string, filterVal: string) => {
    return value === filterVal ? "active" : ""
  }

  return (
    <Layout title={title} location={location}>
      <div className="content-container">
        <article className="playopolis-landing">
          <h1>{title}</h1>
          <div className="filters">
            <button
              className={isActiveButton("Episode", filter)}
              onClick={() => handleFilter("Episode")}
            >
              Podcast
            </button>
            <button
              className={isActiveButton("Article", filter)}
              onClick={() => handleFilter("Article")}
            >
              Article
            </button>
          </div>
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="tiles">
            {contentList &&
              contentList.map((content, i) => {
                return <TeaserTile {...content} key={`teatser-tile-${i}`} />
              })}
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default PlayopolisLanding

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1440) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query queryPlayopolisLanding($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      template {
        templateName
      }
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
      wpParent {
        node {
          uri
        }
      }
    }
    posts: allWpPost(sort: { fields: dateGmt, order: DESC }) {
      nodes {
        id
        uri
        title
        content
        template {
          templateName
        }
        featuredImage {
          node {
            localFile {
              ...HeroImage
            }
          }
        }
      }
    }
  }
`
