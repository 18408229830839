import React from "react"
import { navigate, Link } from "gatsby"

import BackArrowLong from "../components/icons/long-back-arrow"
import BackArrowShort from "../components/icons/short-back-arrow"

import SixtyFourLogo from "../components/icons/64-logo"
import PlayoplisLogo from "../components/icons/playopolis-logo"
import { internalURLs, themes } from "../common/settings"
import { windowGlobal } from "../utils/window"

import Info from "./icons/info"

export interface IModalHeaderProps {
  backTitle: string
  backLocation: any
  site: string
  theme?: string
  action?: any
  modalLocation?: string
}
export default ({
  backTitle,
  backLocation = -1,
  site,
  theme,
  action = undefined,
  modalLocation,
}: IModalHeaderProps) => (
  <header className="modal-header">
    <h1>
      <a href="#" onClick={() => navigate(backLocation)}>
        {windowGlobal && windowGlobal.innerWidth >= 1024 ? (
          <BackArrowLong stroke={theme || themes[site]} />
        ) : modalLocation.includes("landing-page") ? (
          <BackArrowLong stroke={theme || themes[site]} />
        ) : (
          <BackArrowShort stroke={theme || themes[site]} />
        )}
      </a>
      <span className="backTitle">{backTitle}</span>
    </h1>
    {action != undefined ? (
      <button className="action-button" onClick={action}>
        <Info />
      </button>
    ) : null}
    <Link className="close" to={internalURLs[site]}>
      {site === "64ways" ? (
        <SixtyFourLogo fill={theme || themes[site]} />
      ) : (
        <PlayoplisLogo fill={theme || themes[site]} />
      )}
    </Link>
  </header>
)
