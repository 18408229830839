import React, { useState, useEffect, useCallback } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "../components/icons/animated-logo"
import { useTimeout } from "../utils/timers"
import Play from "../assets/svg/play.svg"
import Badge from "../assets/svg/badge.svg"
import CV from "../assets/images/cv.png"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { internalURLs } from "../common/settings"

const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback)
    return () => window.removeEventListener(event, callback)
  }, [event, callback])
}

const SixtyFourAbout = ({ data, location }) => {
  const [autoplay, setAutplay] = useState(false)
  const [logoVisible, setLogoVisible] = useState(true)

  const {
    page: {
      title,
      sixtyFourAbout: {
        tagline,
        storeLink,
        description,
        detail,
        videoThumbnail,
        testimonials,
        collaborators,
        videoUrl,
      },
    },
  } = data

  const redirect = location?.state?.redirect || false
  const [doShrinkLogo, setDoShrinkLogo] = useState(redirect)

  useTimeout(
    () => {
      setDoShrinkLogo(false)
    },
    doShrinkLogo ? 500 : null
  )

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const handleScroll = (e) => {
    const scrollTop = e.srcElement.scrollingElement.scrollTop

    if (scrollTop > 400 && logoVisible) {
      setLogoVisible(false)
    } else if (scrollTop < 400 && !logoVisible) {
      setLogoVisible(true)
    }
  }

  useWindowEvent("scroll", handleScroll)

  return (
    <Layout
      location={location}
      title={title}
      className={`about ${!doShrinkLogo ? "shrunk" : ""}`}
    >
      <Logo
        fill="#edeae3"
        animate={false}
        className={`big-logo ${logoVisible ? "visible" : "hidden"}`}
      />

      <div className="content-container">
        <article>
          <h1 dangerouslySetInnerHTML={{ __html: tagline }} />

          {storeLink && (
            <div
              dangerouslySetInnerHTML={{ __html: storeLink }}
              className="store-link"
            />
          )}

          <div className="video">
            <div
              className="video-inner"
              style={{
                backgroundImage: `url(${videoThumbnail.localFile.childImageSharp.fluid.src})`,
              }}
            >
              {autoplay ? (
                <iframe
                  src={`${videoUrl}?autoplay=1&loop=1&autopause=0`}
                  width="fit-content"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              ) : null}

              {!autoplay ? (
                <>
                  <button onClick={() => setAutplay(true)}>
                    <span>
                      <img src={Play} alt="Play video" />
                      Play
                    </span>
                  </button>
                  <div className="hype">
                    <Link to={internalURLs.app}>Download and play here</Link>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="description"
          />

          <div className="detail">
            <div className="detail-badge">
              <img src={Badge} alt="64 Ways of Being" />
            </div>
            <div
              className="detail-copy"
              dangerouslySetInnerHTML={{ __html: detail }}
            />
          </div>

          <div className="testimonials">
            <Slider {...sliderSettings}>
              {testimonials.map(({ copy, name, url }, i) => (
                <div className="testimonial" key={`testimonial-${i}`}>
                  <div
                    className="testimonial-copy"
                    dangerouslySetInnerHTML={{ __html: copy }}
                  />
                  <div className="testimonial-name">
                    {url ? (
                      <a href={url}>
                        <h4>{name}</h4>
                      </a>
                    ) : (
                      <h4>{name}</h4>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </article>
      </div>

      <div className="collaborators">
        <div className="content-container">
          <div className="collaborators-top">
            <h3>
              64 Ways of Being is supported by the Victorian Government through
              Creative Victoria.
            </h3>
            <a href="https://creative.vic.gov.au/" target="_blank">
              <img src={CV} alt="Creative Victoria" />
            </a>
          </div>
          <div className="tiles">
            {collaborators &&
              collaborators.map(({ logo, url }, i) => (
                <div className="tile" key={`collaborator-${i}`}>
                  {url ? (
                    <a href={url}>
                      <Img fluid={logo.localFile.childImageSharp.fluid} />
                    </a>
                  ) : (
                    <Img fluid={logo.localFile.childImageSharp.fluid} />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SixtyFourAbout

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1440) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
  fragment Logo on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query querySixtyFourWaysAbout($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      sixtyFourAbout {
        tagline
        description
        detail
        storeLink
        testimonials {
          copy
          name
          url
        }
        collaborators {
          url
          logo {
            localFile {
              ...Logo
            }
          }
        }
        videoThumbnail {
          localFile {
            ...HeroImage
          }
        }
        videoUrl
      }
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
    }
  }
`
