import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"

export default () => {
  const [isHome, setIsHome] = useState(false)
  const [activeSite, setActiveSite] = useState("64ways")
  const location = useLocation()

  useEffect(() => {
    setIsHome(location.pathname === "/")
    setActiveSite(
      location.pathname.startsWith("/playopolis/") ? "playopolis" : "64ways"
    )
  }, [location.pathname])

  return isHome ? null : (
    <footer className={activeSite}>
      <div className="footer-inner">
        <div className="footer-block">&copy; 64 WAYS OF BEING</div>
        {activeSite === "playopolis" ? (
          <div className="footer-block">
            64 Ways of Being is a free augmented reality app that allows players
            to see Melbourne through new eyes. Coming in April, 2021.
          </div>
        ) : (
          <div className="footer-block">
            64 Ways of Being is a free augmented reality app that allows players
            to see Melbourne through new eyes.
          </div>
        )}
        <nav>
          <a href="https://www.facebook.com/64waysofbeing">FB</a>
          <a href="https://www.instagram.com/64waysofbeing/">IG</a>
          <a href="https://twitter.com/64waysofbeing">TW</a>
        </nav>
      </div>
    </footer>
  )
}
