import React from "react"
import { graphql } from "gatsby"
// import PlayopolisMapEpisode from "./template-parts/post-playopolis-episode-map"
import PlayopolisStandardEpisode from "./template-parts/post-playopolis-episode-standard"

function PlayopolisEpisode(props) {
  const { page } = props.data
  const {
    playopolisEpisodeData: { type },
  } = page

  return type === "map" ? (
    <PlayopolisStandardEpisode {...props} />
  ) : (
    <PlayopolisStandardEpisode {...props} />
  )
}

export default PlayopolisEpisode

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1440) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query queryPostPlayopolisEpisode($id: String!) {
    page: wpPost(id: { eq: $id }) {
      title
      content
      playopolisEpisodeData {
        episodeAudio {
          mediaItemUrl
        }
        episodeNumber
        type
        locationData {
          content
          title
          location {
            latitiude
            longitude
          }
        }
      }
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
    }
  }
`
