import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout title="Page not found">
    <div className="content-container">
      <p>Sorry, that page could not be found</p>
    </div>
  </Layout>
)
