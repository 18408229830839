import React from "react"
import ModalHeader from "./modal-header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import "../styles/style.scss"
import { siteTitle, internalURLs } from "../common/settings"
import Hat from "./hat"
import { useLocation } from "@reach/router"

const ModalLayout = ({ children = null, title, headerProps, ...rest }) => {
  const location = useLocation()

  const activeSite =
    location && location.pathname.startsWith(internalURLs.playopolis)
      ? "playopolis"
      : "64ways"

  let extraProps = { ...rest }
  const propsClass = extraProps.className || ""
  const className = `layout-wrap ${propsClass} ${activeSite}`

  return (
    <div className={className}>
      <Hat title={title} />
      <ModalHeader
        {...headerProps}
        modalLocation={propsClass}
        site={activeSite}
      />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default ModalLayout
