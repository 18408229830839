import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Ext from "../components/icons/ext"
import { darkFill } from "../common/settings"
import SixtyFourLogo from "../components/icons/64-logo"

function PlayopolisAbout({ data, location }) {
  const { page } = data
  const {
    title,
    playopolisAbout: {
      contentLeft,
      contentRight,
      listenLink,
      tagline,
      listenLinkCopy,
    },
  } = page

  return (
    <Layout location={location} title={title}>
      <div className="content-container">
        <article className="playopolis-about">
          <h2>{tagline}</h2>
          <a className="listen-link" href={listenLink} target="_blank">
            {listenLinkCopy}
            <Ext fill={darkFill} />
          </a>
          <div className="main-content">
            <div className="content-left">
              <div dangerouslySetInnerHTML={{ __html: contentLeft }}></div>
              <div className="content-left-credit">
                <span>By</span>
                <SixtyFourLogo fill={darkFill} />
              </div>
            </div>
            <div
              className="content-right"
              dangerouslySetInnerHTML={{ __html: contentRight }}
            />
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default PlayopolisAbout

export const query = graphql`
  query queryPlayopolisAbout($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      playopolisAbout {
        contentLeft
        contentRight
        listenLink
        tagline
        listenLinkCopy
      }
      wpParent {
        node {
          uri
        }
      }
    }
  }
`
