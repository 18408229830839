import React from "react"
import { graphql } from "gatsby"
import { internalURLs } from "../common/settings"
import ModalLayout from "../components/modal-layout"
import { darkFill } from "../common/settings"

function PlayopolisArticle({ data, location }) {
  const { page } = data
  const { title, content } = page

  const parentURI = internalURLs.siteArchive
  const parentTitle = "Explore"

  return (
    <ModalLayout
      location={location}
      title={title}
      headerProps={{
        backLocation: parentURI || -1,
        backTitle: parentTitle || "Back",
        theme: darkFill,
      }}
      className="page playopolis-article"
    >
      <div className="content-container-narrow">
        <article>
          <h1 className="article-title">{title}</h1>
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </article>
      </div>
    </ModalLayout>
  )
}

export default PlayopolisArticle

export const query = graphql`
  query queryPostPlayopolisArticle($id: String!) {
    page: wpPost(id: { eq: $id }) {
      title
      content
      template {
        templateName
      }
    }
  }
`
