import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

export default ({ title }) => {
  const location = useLocation()

  const activeSite =
    location && location.pathname.startsWith("/playopolis/")
      ? "playopolis"
      : "64ways"

  const siteTitle = {
    "64ways": "64 Ways of Being",
    playopolis: "Playopolis",
  }

  const meta = {
    "64ways": {
      image: "64-ways-1200x628.jpg",
      description:
        "64 Ways of Being is a free augmented reality app that allows players to see Melbourne through new eyes.",
      url: "https://64waysofbeing.com/",
    },
    playopolis: {
      image: "playopolis-1200x628.jpg",
      description:
        "Playopolis is a Melbourne-made podcast about the places people play.",
      url: "https://64waysofbeing.com/playopolis",
    },
  }

  const displayTitle = title ? `${title} - ` : ""

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${displayTitle}${siteTitle[activeSite]}`}</title>
      <link
        rel="shortcut icon"
        href={`/${activeSite}-favicon.ico`}
        type="image/x-icon"
      ></link>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={meta[activeSite].url} />
      <meta property="og:title" content={siteTitle[activeSite]} />
      <meta property="og:description" content={meta[activeSite].description} />
      <meta
        property="og:image"
        content={`https://64waysofbeing.com/${meta[activeSite].image}`}
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={meta[activeSite].url} />
      <meta property="twitter:title" content={siteTitle[activeSite]} />
      <meta
        property="twitter:description"
        content={meta[activeSite].description}
      />
      <meta
        property="twitter:image"
        content={`https://64waysofbeing.com/${meta[activeSite].image}`}
      />
    </Helmet>
  )
}
