export const lightFill = "#EDEAE3"
export const darkFill = "#0a0a0a"

export const internalURLs = {
  "64ways": "/",
  playopolis: "/playopolis/",
  siteArchive: "/playopolis/explore/",
  playtest: "/playtest/",
  app: "/app/",
}

export const siteTitle = {
  "64ways": "64 Ways of Being",
  playopolis: "Playopolis",
}

export const themes = {
  "64ways": lightFill,
  playopolis: darkFill,
}
