import React, { useRef, useEffect, useState } from "react"
import { graphql } from "gatsby"
import mapboxgl, { Map } from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import CloseCircle from "../assets/svg/close-circle.svg"
import ArrowUp from "../assets/svg/up.svg"

import useDimensions from "react-use-dimensions"
import ModalLayout from "../components/modal-layout"
import { windowGlobal } from "../utils/window"

interface SidebarData {
  title: string
  content: string
}

interface MarkerData extends SidebarData {
  id: string
  latitude: number
  longitude: number
}

const SixtyFourMapListing = ({ data, location }) => {
  const { page } = data
  const { title, content, wpChildren, wpParent } = page
  const [sidebarRef, { width: sidebarWidth, height: sidebarHeight }] =
    useDimensions()
  const sidebarWidthRef = useRef(0)
  const sidebarHeightRef = useRef(0)

  const [sidebarData, setSidebarData] = useState<
    null | MarkerData | SidebarData
  >({
    title,
    content,
  })
  const [isMapShowing, setIsMapShowing] = useState("hidden")
  const { uri: parentURI, title: parentTitle } = wpParent?.node || {}

  const { nodes: markers } = wpChildren

  let markerData: MarkerData[] = []
  markers &&
    markers.forEach((marker) => {
      const { content, title, id, sixtyFourMapListingLocation } = marker
      const { location } = sixtyFourMapListingLocation || {}
      const { latitude, longitude } = location || {}

      if (latitude && longitude) {
        markerData.push({
          id,
          title,
          content,
          latitude,
          longitude,
        })
      }
    })

  const mapDiv = useRef(null)
  const mapRef = useRef<Map | null>(null)

  let geoJSON: any = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  }

  markerData.forEach((markerDatum) => {
    geoJSON.data.features.push({
      type: "Feature",
      properties: {
        ...markerDatum,
      },
      geometry: {
        type: "Point",
        coordinates: [markerDatum.longitude, markerDatum.latitude],
      },
    })
  })

  const clearActiveMarkers = () => {
    const markerElems = document.querySelectorAll(".image-marker")
    markerElems.forEach((markerElem) => {
      markerElem.classList.remove("active")
    })
  }

  useEffect(() => {
    if (mapDiv.current !== null) {
      mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN

      const map = new mapboxgl.Map({
        container: mapDiv.current || "",
        style: "mapbox://styles/mapbox/dark-v10",
        zoom: 2,
      })

      map.on("load", () => setIsMapShowing("showing"))

      // add markers to map
      markerData.forEach(function (marker) {
        let { id, longitude, latitude } = marker
        let el = document.createElement("div")
        el.className = "image-marker"

        el.addEventListener("click", () => {
          const postData = markerData.find((m) => m.id === id)
          setSidebarData({ ...postData })

          clearActiveMarkers()
          el.classList.add("active")

          // add a little offset if the sidebar is showing, so the point centers in the remaining space
          const offsetX = !!sidebarData
            ? (sidebarWidthRef.current || 0) / 2 || 0
            : 200

          map.flyTo({
            center: [longitude, latitude],
            offset: [-offsetX, 0],
          })
        })
        // make a marker for each feature and add to the map
        new mapboxgl.Marker(el)
          .setLngLat([marker.longitude, marker.latitude])
          .addTo(map)
      })

      mapRef.current = map
      showAll(true, true)
    }
  }, [])

  // correct the zoom when sidebar width is known
  useEffect(() => {
    showAll(!!sidebarData)
    sidebarWidthRef.current = sidebarWidth
    sidebarHeightRef.current = sidebarHeight
  }, [sidebarWidth, sidebarHeight])

  // Show all markers in marker data on screen at once
  const showAll = (sidebarVisible: boolean = false, firstTime = false) => {
    if (markerData.length > 0) {
      const bounds = new mapboxgl.LngLatBounds()
      for (let m of markerData) {
        bounds.extend([m.longitude, m.latitude])
      }

      if (mapRef.current) {
        let fitOptions: mapboxgl.FitBoundsOptions = {
          padding: {
            top: 200,
            bottom: 200,
            left: 200,
            right: sidebarVisible ? (sidebarWidth || 0) + 200 : 200,
          },
          maxZoom: 16,
        }

        // no big zoom the first time,
        // just show all the markers
        if (firstTime) {
          fitOptions.duration = 0
        }

        mapRef.current.fitBounds(bounds, fitOptions)
      }
    }
  }

  const closeSidebar = () => {
    setSidebarData(null)
    showAll(false)
    clearActiveMarkers()
  }

  return (
    <ModalLayout
      location={location}
      title={title}
      headerProps={{
        backLocation: parentURI || -1,
        backTitle: parentTitle || "Back",
        action: () => {
          setSidebarData({
            title,
            content,
          })
          showAll(true)
        },
      }}
      className="map-listing"
    >
      <div ref={mapDiv} className={`map-container ${isMapShowing}`} />
      <article>
        {sidebarData && (
          <div className={` map-listing-sidebar`} ref={sidebarRef}>
            <button className="closeBtn" onClick={closeSidebar}>
              <img src={CloseCircle} alt="close marker content" />
            </button>

            <button onClick={closeSidebar} className="upBtn">
              <img src={ArrowUp} alt="open description" />
            </button>
            <aside>
              <h2>{sidebarData.title}</h2>
              <div
                className="article-content"
                dangerouslySetInnerHTML={{ __html: sidebarData.content }}
              />
            </aside>
          </div>
        )}
      </article>
    </ModalLayout>
  )
}

export default SixtyFourMapListing

export const query = graphql`
  query querySixtyFourWaysMapListing($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      template {
        templateName
      }
      wpParent {
        node {
          uri
          ... on WpPage {
            id
            title
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            title
            content
            sixtyFourMapListingLocation {
              location {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  }
`
