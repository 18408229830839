import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import oblong from "../assets/svg/oblong.svg"
import disc from "../assets/svg/disc.svg"

const shapes = { disc, oblong }

const SixtyFourExplore = ({ data, location }) => {
  const { page } = data
  const { wpChildren, title } = page

  const islands = []
  wpChildren?.nodes?.forEach((island, i) => {
    if (i % 4 === 0 && i !== 0) {
      islands.push(
        <div key={`${i}-${island.id}-blank`} className="island"></div>
      )
    }
    const shape = i % 2 === 0 ? "disc" : "oblong"

    islands.push(
      <div key={`${i}-${island.id}`} className="island">
        <Link to={island.uri}>
          <h3>{island.title}</h3>
          <img src={shapes[shape]} alt={`${shape} island`} />
        </Link>
      </div>
    )
  })

  return (
    <Layout location={location} title={title} className="sixty-four-explore">
      <div className="content-container">
        <div className="archipelago">{islands}</div>
      </div>
    </Layout>
  )
}

export default SixtyFourExplore

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1440) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query querySixtyFourWaysExplore($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      template {
        templateName
      }
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpPage {
            id
            uri
            title
          }
        }
      }
    }
  }
`
