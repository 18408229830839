import React from "react"
import Menu from "./menu"
import { Link } from "gatsby"
import SixtyFourLogo from "../components/icons/64-logo"
import PlayoplisLogo from "../components/icons/playopolis-logo"
import { useLocation } from "@reach/router"

export default () => {
  const location = useLocation()
  const isSixtyFour = !location || !location.pathname.startsWith("/playopolis/")

  return (
    <header>
      <Link className="logo" to={isSixtyFour ? "/" : "/playopolis/"}>
        {isSixtyFour ? <SixtyFourLogo /> : <PlayoplisLogo />}
      </Link>
      <Menu />
    </header>
  )
}
