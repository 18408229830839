import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { normalizePath } from "../utils/get-url-path"
import { useLocation } from "@reach/router"
import { lightFill, darkFill } from "../common/settings"
import Ext from "../components/icons/ext"
import Close from "../assets/svg/close.svg"
import HamburgerLight from "../assets/svg/burger-light.svg"
import HamburgerDark from "../assets/svg/burger-dark.svg"

export default () => {
  const location = useLocation()
  const [activeNav, setActiveNav] = useState("main-nav")
  const fill = activeNav === "main-nav" ? lightFill : darkFill
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setActiveNav(
      location.pathname.startsWith("/playopolis/")
        ? "playopolis-nav"
        : "main-nav"
    )
  }, [location.pathname])

  const { allWpMenu } = useStaticQuery(graphql`
    {
      allWpMenu {
        nodes {
          slug
          name
          menuItems {
            nodes {
              label
              url
              parentId
              target
              cssClasses
            }
          }
        }
      }
    }
  `)

  const wpMenu = allWpMenu.nodes?.find((node) => node.slug === activeNav)

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <>
      <div className="mobileMenuBtn" onClick={() => setOpen(!open)}>
        {location.pathname.startsWith("/playopolis/") ? (
          <img src={HamburgerDark} alt="open menu" />
        ) : (
          <img src={HamburgerLight} alt="open menu" />
        )}
      </div>

      <nav className={open ? "showMobileMenu" : "hideMobileMenu"}>
        <div className="mobileCloseBtn" onClick={() => setOpen(!open)}>
          <img src={Close} alt="close menu" />
        </div>
        <div className="menuWrapper">
          {wpMenu.menuItems.nodes.map((menuItem, i) => {
            if (menuItem.parentId) {
              return null
            }

            const path = menuItem.url

            // make the dots
            let dots = []
            if (i < wpMenu.menuItems.nodes.length - 1) {
              for (let j = 0; j < i + 1; j++) {
                dots.push(<span key={`dot-${j}`} className="dot" />)
              }
            }
            const dotsWrapper = <span className="dots">{dots}</span>

            return (
              <Link
                key={i + menuItem.url}
                style={{ display: `block` }}
                to={normalizePath(path)}
                target={menuItem.target}
                onClick={() => setOpen(!open)}
              >
                {menuItem.label}
                {menuItem.cssClasses.includes("ext") && <Ext fill={fill} />}
                {dotsWrapper}
              </Link>
            )
          })}
        </div>

        <div className="menuFooter">
          <hr />
          <div className="menuFooterLinks">
            <a href="https://www.facebook.com/64waysofbeing">FB</a>
            <a href="https://www.instagram.com/64waysofbeing/">IG</a>
            <a href="https://twitter.com/64waysofbeing">TW</a>
          </div>
        </div>
      </nav>
    </>
  ) : null
}
