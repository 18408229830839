import React from "react"

export default ({ stroke = "#EDEAE3" }) => {
  return (
    <svg
      width="127"
      height="16"
      viewBox="0 0 127 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.707108L0 7.70711L7 14.7071"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path d="M0.135742 7.70711H127" stroke={stroke} strokeWidth="1.5" />
    </svg>
  )
}
