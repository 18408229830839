import React, { useEffect, useRef, useState } from "react"
import Img from "gatsby-image"
import { internalURLs } from "../../common/settings"
import ModalLayout from "../../components/modal-layout"
import AudioPlayer from "../../components/audio-player"
import { darkFill } from "../../common/settings"

function PlayopolisStandardEpisode({ data, location }) {
  const { page } = data
  const {
    title,
    content,
    featuredImage,
    playopolisEpisodeData: { episodeNumber, type, episodeAudio },
  } = page

  const parentURI = internalURLs.siteArchive
  const parentTitle = "Explore"
  const audioFile = episodeAudio?.mediaItemUrl || null

  console.log(episodeAudio)

  return (
    <ModalLayout
      location={location}
      title={title}
      headerProps={{
        backLocation: parentURI || -1,
        backTitle: parentTitle || "Back",
        theme: darkFill,
      }}
      className="page playopolis-episode"
    >
      <div className="content-container">
        <article>
          <div className="episode-layout">
            <div className="episode-content">
              <h4 className="episode-number">
                Episode {String(episodeNumber).padStart(3, "0")}
              </h4>
              <h1 className="article-title">{title}</h1>
              {audioFile && <AudioPlayer audioFile={audioFile} />}
              <div
                className="article-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            <div className="episode-image">
              {!!featuredImage?.node?.localFile?.childImageSharp && (
                <Img
                  fluid={featuredImage.node.localFile.childImageSharp.fluid}
                />
              )}
            </div>
          </div>
        </article>
      </div>
    </ModalLayout>
  )
}

export default PlayopolisStandardEpisode
