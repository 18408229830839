import React from "react"
import { Link } from "gatsby"

export default ({ uri, title, category, featuredImage }) => (
  <Link className="landing-page-tile" to={uri}>
    <div className="tile-header">
      <div className={`dots ${category.toLowerCase()}`}></div>
      <div className="tile-type">{category}</div>
    </div>
    <div className="feature-image">
      <div
        className="feature-image-inner"
        style={{
          backgroundImage: `url(${featuredImage?.node?.localFile.childImageSharp.fluid.src})`,
        }}
      ></div>
    </div>
    <h3>{title}</h3>
  </Link>
)
