import React from "react"
import { graphql } from "gatsby"
import ModalLayout from "../components/modal-layout"
import { darkFill } from "../common/settings"

function Page({ data, location }) {
  const { page } = data
  const { title, content, wpParent } = page
  const { uri: parentURI, title: parentTitle } = wpParent?.node || {}

  return (
    <ModalLayout
      location={location}
      title={title}
      headerProps={{
        backLocation: parentURI || -1,
        backTitle: parentTitle || "Back",
        theme: darkFill,
      }}
      className="page"
    >
      <div className="content-container-narrow">
        <article>
          <h1 className="article-title">{title}</h1>
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </article>
      </div>
    </ModalLayout>
  )
}

export default Page

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1440) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }

  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      template {
        templateName
      }
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
      wpParent {
        node {
          uri
          ... on WpPage {
            id
            title
          }
        }
      }
    }
  }
`
