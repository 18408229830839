// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-playopolis-about-page-tsx": () => import("./../../../src/templates/page-playopolis-about-page.tsx" /* webpackChunkName: "component---src-templates-page-playopolis-about-page-tsx" */),
  "component---src-templates-page-playopolis-landing-tsx": () => import("./../../../src/templates/page-playopolis-landing.tsx" /* webpackChunkName: "component---src-templates-page-playopolis-landing-tsx" */),
  "component---src-templates-page-sixty-four-ways-about-page-tsx": () => import("./../../../src/templates/page-sixty-four-ways-about-page.tsx" /* webpackChunkName: "component---src-templates-page-sixty-four-ways-about-page-tsx" */),
  "component---src-templates-page-sixty-four-ways-explore-tsx": () => import("./../../../src/templates/page-sixty-four-ways-explore.tsx" /* webpackChunkName: "component---src-templates-page-sixty-four-ways-explore-tsx" */),
  "component---src-templates-page-sixty-four-ways-landing-tsx": () => import("./../../../src/templates/page-sixty-four-ways-landing.tsx" /* webpackChunkName: "component---src-templates-page-sixty-four-ways-landing-tsx" */),
  "component---src-templates-page-sixty-four-ways-map-listing-tsx": () => import("./../../../src/templates/page-sixty-four-ways-map-listing.tsx" /* webpackChunkName: "component---src-templates-page-sixty-four-ways-map-listing-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-playopolis-article-tsx": () => import("./../../../src/templates/post-playopolis-article.tsx" /* webpackChunkName: "component---src-templates-post-playopolis-article-tsx" */),
  "component---src-templates-post-playopolis-episode-tsx": () => import("./../../../src/templates/post-playopolis-episode.tsx" /* webpackChunkName: "component---src-templates-post-playopolis-episode-tsx" */)
}

